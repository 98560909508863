import 'bootstrap'
import * as $ from 'jquery'
import './../styles/styles.scss'

function runModule(module) {
	// let page = module.default
	// $(document).ready(function () {
	// 	page()
	// })
}

$(document).ready(function () {
	switch (jsPage) {
		case 'login':
			// console.log('index.js load login')
			import(/* webpackChunkName: "login" */ './login').then(runModule)
			break
		case 'dashboard':
			// console.log('index.js load dashboard')
			import(/* webpackChunkName: "dashboard" */ './dashboard').then(runModule)
			break
		case 'activationForm':
			// console.log('index.js load activationForm')
			import(/* webpackChunkName: "activationForm" */ './activationForm').then(runModule)
			break
		case 'newDevice':
			// console.log('index.js load newDevice')
			import(/* webpackChunkName: "activationForm" */ './newDevice').then(runModule)
			break
		case 'addVehicle':
			// console.log('index.js load addVehicle')
			import(/* webpackChunkName: "addVehicle" */ './addVehicle').then(runModule)
			break
		case 'addCustomer':
			// console.log('index.js load addCustomer')
			import(/* webpackChunkName: "addCustomer" */ './addCustomer').then(runModule)
			break
		case 'completionForm':
			// console.log('index.js load completionForm')
			import(/* webpackChunkName: "addCustomer" */ './completionForm').then(runModule)
			break
		case 'forgotPassword':
			// console.log('index.js load forgotPassword')
			import(/* webpackChunkName: "forgotPassword" */ './forgotPassword').then(runModule)
			break
		case 'imageUpload':
			// console.log('index.js load imageUpload')
			import(/* webpackChunkName: "imageUpload" */ './imageUpload').then(runModule)
			break
		case 'jobs':
			// console.log('index.js load jobs')
			import(/* webpackChunkName: "imageUpload" */ './jobs').then(runModule)
			break
		case 'completedActivations':
			// console.log('index.js load completedActivations')
			import(/* webpackChunkName: "imageUpload" */ './completedActivations').then(runModule)
			break
		default:
			// console.log('no js page loaded')
			break
	}
})
